export function Footer(props){
    return (
        <footer>
            <div className="footer">
                <h1>Nathan Do</h1>
                <a href="https://www.linkedin.com/in/nathan-do-a3b620240/" className="footer-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" className="footer-icon">
                        <path d="M27.5994 0.00269046H2.56869C2.24607 -0.0124207 1.92361 0.0362316 1.61981 0.14589C1.31601 0.255549 1.03683 0.424039 0.798226 0.641707C0.559638 0.859393 0.366311 1.122 0.229345 1.41447C0.0923795 1.70697 0.0144372 2.02359 0 2.34623V27.4315C0.0188996 28.1066 0.295605 28.7491 0.773288 29.2266C1.25097 29.7043 1.89341 29.9811 2.56869 30H27.5994C28.2558 29.9708 28.8747 29.6854 29.3234 29.2053C29.7721 28.7251 30.0147 28.0884 29.9993 27.4315V2.34623C30.0047 2.03219 29.9459 1.72039 29.8264 1.4299C29.707 1.13944 29.5294 0.876417 29.3046 0.656968C29.08 0.437538 28.8128 0.266329 28.5196 0.153821C28.2264 0.0413124 27.9132 -0.0101146 27.5994 0.00269046ZM9.37478 25.013H5.00613V11.6454H9.37478V25.013ZM7.29358 9.62056C6.98873 9.63119 6.68497 9.57866 6.4014 9.46628C6.11782 9.3539 5.86053 9.1841 5.64574 8.96754C5.43092 8.75098 5.26321 8.49234 5.15315 8.20788C5.04307 7.92341 4.99303 7.61926 5.00613 7.31452C4.99265 7.00649 5.04371 6.69905 5.15604 6.41192C5.26837 6.12477 5.43947 5.86429 5.65838 5.64716C5.8773 5.43002 6.13917 5.26104 6.42722 5.15106C6.71529 5.04107 7.02316 4.99251 7.33108 5.00848C7.63593 4.99787 7.93969 5.05039 8.22326 5.16276C8.50685 5.27514 8.76413 5.44494 8.97892 5.6615C9.19374 5.87807 9.36145 6.1367 9.47151 6.42117C9.58159 6.70563 9.63163 7.00979 9.61853 7.31452C9.63201 7.62256 9.58095 7.92999 9.46863 8.21712C9.3563 8.50427 9.18519 8.76476 8.96629 8.98188C8.74737 9.19902 8.48549 9.368 8.19744 9.47798C7.90937 9.58798 7.60151 9.63653 7.29358 9.62056ZM25.0119 25.013H20.6245V17.7011C20.6245 15.9575 20.0058 14.7576 18.4496 14.7576C17.9662 14.7618 17.4958 14.9153 17.1032 15.1973C16.7104 15.4793 16.4145 15.8758 16.2559 16.3325C16.134 16.6748 16.083 17.0384 16.1059 17.4012V25.013H11.7935V11.6454H16.1059V13.5203C16.4893 12.8159 17.0599 12.2313 17.7547 11.8309C18.4496 11.4306 19.2416 11.2301 20.0433 11.2517C22.8745 11.2517 25.0119 13.1265 25.0119 17.1199V25.013Z" fill="#9C9589"/>
                    </svg>
                </a>
            </div>
        </footer>
    )
};